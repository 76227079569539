body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-wrapper {
  display: flex;
}

.news-feed {
  background-color: rgb(204, 204, 231);
  padding: 10px;
  margin: 5px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  max-width: 300px;
}

.currency-converter {
  background-color: rgb(230, 223, 211);
  padding: 10px;
  margin: 5px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  text-align: center;
}

.exchange-rate {
  background-color: rgb(221, 230, 221);
  padding: 20px;
  margin-top: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 10px;
}

.input-box {
  background-color: rgb(218, 227, 238);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

button {
  border: none;
  float: right;
  margin: 2px;
}

button:hover {
  background-color: coral;
}

input,
select {
  border: none;
}
